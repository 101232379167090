import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
import Layout from '../components/layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Compai: Generative design`}</h1>
    <p>{`It's 2019, and you're still tweaking pixels.`}</p>
    <hr></hr>
    {
      /* Generated avatars animating */
    }
    <h2>{`It's time to use `}<strong parentName="h2">{`robots`}</strong>{` to improve your workflow.`}</h2>
    <hr></hr>
    <p>{`With Compai you can work faster while still allowing for a bit of `}<strong parentName="p">{`serendipity`}</strong>{`.`}</p>
    {
      /* Icons with numbers overlapping */
    }
    <ol>
      <li parentName="ol">{`Generate components for inspiration`}</li>
      <li parentName="ol">{`Share concepts with your team and vote on variants`}</li>
      <li parentName="ol">{`Curate a moodboard and share with stakeholders`}</li>
      <li parentName="ol">{`Export production-ready components or HTML+CSS`}</li>
    </ol>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`These things are fun and fun is good.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`A design API for the future`}</h2>
    <p>{`Compai provides first-class API support so you can integrate with your favorite tools.`}</p>
    <hr></hr>
    <h2>{`Spec compliant design system`}</h2>
    <p>{`Generated themes comply with the `}<a parentName="p" {...{
        "href": "https://system-ui.com/theme"
      }}>{`System UI specification`}</a>{`
and can be used directly with `}<a parentName="p" {...{
        "href": "https://theme-ui.com"
      }}>{`Theme UI`}</a>{` design system.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      